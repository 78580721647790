<template>
  <section class="section bg-darkslategray" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="feature-detail">
            <div class="title-box">
              <h2
                class="fadeIn animated wow text-white"
                data-wow-delay=".1s"
              >{{$t("KURLABS MANAGER CONTROL")}}</h2>
              <div class="border"></div>
            </div>

            <li
              class="text-white my-3"
            >{{$t("Global visualization of services in test and production environments.")}}</li>

            <li class="text-white my-3">{{$t("Simple and fast application administration.")}}</li>

            <li
              class="text-white my-3"
            >{{$t("Improves monitoring and inspection of application records.")}}</li>
            <li
              class="text-white my-3"
            >{{$t("It allows the display of features such as: RAM, CPU and Network by service.")}}</li>
          </div>
        </div>
        <div class="col-lg-6">
          <img
            src="../../assets/images/manage/control_manager.png"
            class="img-fluid fadeIn animated wow center-block"
            data-wow-delay=".2s"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ManageControl"
};
</script>







